<template>
  <div class="bgc-white p-20 bd" id="mainContent">
    <h4 style="display:inline-block;" class="c-grey-900">{{this.$t('incomeReport')}}</h4>
    <span style="border-right: 1px solid #ddd; margin: 0 10px"></span>
    <div class="form-check form-check-inline">
  <input class="form-check-input" type="radio" name="inlineRadioOptions" v-model="duration" id="inlineRadio1" value="1">
  <label class="form-check-label" for="inlineRadio1">{{ $t('today') }}</label>
</div>
<div class="form-check form-check-inline">
  <input class="form-check-input" type="radio" name="inlineRadioOptions" v-model="duration" id="inlineRadio2" value="2">
  <label class="form-check-label" for="inlineRadio2">{{ $t('month') }}</label>
</div>
<div class="form-check form-check-inline">
  <input class="form-check-input" type="radio" name="inlineRadioOptions" v-model="duration" id="inlineRadio3" value="3">
  <label class="form-check-label" for="inlineRadio3">{{ $t('year') }}</label>
</div>
    
          <form @submit="checkForm" >
              <div class="form-group row pB-30 pT-30">
              
                <div class="col-sm-2">
                  <sui-dropdown
              selection
              :options="years()"
              v-model="currentYear"
              :placeholder="this.$t('year')"
            />
                </div>

                <div class="col-sm-2" v-if="duration != 3">
                  <sui-dropdown
              selection
              :options="months"
              v-model="currentMonth"
              :placeholder="this.$t('month')"
            />
                </div>

                <div class="col-sm-2" v-if="duration ==1">
                  <sui-dropdown
              selection
              :options="days()"
              v-model="currentDay"
              :placeholder="this.$t('day')"
            />
                </div>
                <div class="form-group col-md-3">
            <select style="height:38px" id="inputState" class="form-control" v-model="branch_id" :placeholder="$t('branch')">
              <option  value="">{{ $t('allBranches') }}</option>
              <option  v-for="item in data.branches_names" :key="item.id" :value="item.id">{{ getTranslation(item.ar_name , item.en_name) }}</option>
            </select>
          </div>
                
                <div class="col-sm-2">
                  <loading v-if="loading" />
                  <button
                  v-else
                    type="submit"
                    class="btn btn-primary mL-10"
                    style="height: 37px;"
                    :disabled="loading"
                  >
                    {{ $t("search") }}
                  </button>
                </div>
              </div>
            </form>
            
    <div  class="row gap-20">
      <div class="col-md-3">
        <div class="layers bd bgc-white p-20">
          <div class="layer w-100 mB-10">
            <h6 class="lh-1">{{$t('totalIncome')}}</h6>
          </div>
          <div class="layer w-100">
            <div class="peers ai-sb fxw-nw">
              <div class="peer">
                <span class="d-ib lh-0 va-m fw-600 bdrs-10em pX-15 pY-15 bgc-green-50 c-green-500">{{data.total_income +' '+$t('rial') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="layers bd bgc-white p-20">
          <div class="layer w-100 mB-10">
            <h6 class="lh-1">{{$t('totalCost')}}</h6>
          </div>
          <div class="layer w-100">
            <div class="peers ai-sb fxw-nw">
              <div class="peer peer-greed"></div>
              <div class="peer">
                <span class="d-ib lh-0 va-m fw-600 bdrs-10em pX-15 pY-15 bgc-red-50 c-red-500">{{data.total_cost+' '+$t('rial')}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="layers bd bgc-white p-20">
          <div class="layer w-100 mB-10">
            <h6 class="lh-1">{{hasProfit() ? $t('netProfit') : $t('netLoss')}}</h6>
          </div>
          <div class="layer w-100">
            <div class="peers ai-sb fxw-nw">
              <div class="peer">
                <span v-if="hasProfit()" class="d-ib lh-0 va-m fw-600 bdrs-10em pX-15 pY-15 bgc-green-50 c-green-500">{{data.total_income - data.total_cost +' '+$t('rial')}}</span>
                <span v-else class="d-ib lh-0 va-m fw-600 bdrs-10em pX-15 pY-15 bgc-red-50 c-red-500">{{(data.total_income - data.total_cost)*-1 +' '+$t('rial')}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="layers bd bgc-white p-20">
          <div class="layer w-100 mB-10">
            <h6 class="lh-1">{{$t('profitMargin')}}</h6>
          </div>
          <div class="layer w-100">
            <div class="peers ai-sb fxw-nw">
              <div class="peer">
                <span v-if="hasProfit()" class="d-ib lh-0 va-m fw-600 bdrs-10em pX-15 pY-15 bgc-green-50 c-green-500">{{data.total_income == 0 ?0+'%' : (((data.total_income - data.total_cost) / data.total_income) * 100).toFixed(2)+'%'}}</span>
                <span v-else class="d-ib lh-0 va-m fw-600 bdrs-10em pX-15 pY-15 bgc-red-50 c-red-500">{{data.total_income == 0 ?0+'%' : (((data.total_income - data.total_cost) / data.total_income) * 100).toFixed(2)+'%'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mT-100">
      <div class="col-md-12">
        <h4 class="c-grey-900" style="text-align: center;">{{this.$t('incomeByClothType')}}</h4>
        <div>
          <canvas ref="BranchesbarChartCanvas" width="200" height="200"></canvas>
        </div>
      </div>
    </div>
    <div class="row mT-100">
      <div class="col-md-6">
        <h4 style="text-align: center;" class="c-grey-900">{{this.$t('incomeByWashType')}}</h4>
        <div>
          <canvas ref="pieChartCanvas" width="200" height="200"></canvas>
        </div>
      </div>
      <div class="col-md-6">
        <h4 style="text-align: center;" class="c-grey-900">{{this.$t('costs')}}</h4>
        <div>
          <canvas ref="costPieChartCanvas" width="200" height="200"></canvas>
        </div>
      </div>
    </div>
    <div class="row mT-100">
      <div class="col-md-12">
        <h4 class="c-grey-900" style="text-align: center;">{{this.$t('incomeByClothType')}}</h4>
        <div>
          <canvas ref="barChartCanvas" width="200" height="200"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import chroma from 'chroma-js';
import Chart from 'chart.js';
  export default {
    data() {
      return {
        data: {},
        loading: false,
        date: '',
        branch_id:'',
        months: [
        { text: this.$t('january'), value: 1 },
        { text: this.$t('february'), value: 2 },
        { text: this.$t('march'), value: 3 },
        { text: this.$t('april'), value: 4 },
        { text: this.$t('may'), value: 5 },
        { text: this.$t('june'), value: 6 },
        { text: this.$t('july'), value: 7 },
        { text: this.$t('august'), value: 8 },
        { text: this.$t('september'), value: 9 },
        { text: this.$t('october'), value: 10 },
        { text: this.$t('november'), value: 11 },
        { text: this.$t('december'), value: 12 },
      ],
      duration: 2,
      currentMonth: null,
      currentYear:null,
      currentDay:null,
      maxYear:null,
      barChart: null,
      branchesBarChart:null,
      pieChart:null,
      costPieChart:null
      };
    },
    created() {
      this.getReport();
    },
    methods: {
      checkForm: function (e) {
        e.preventDefault();
        this.getReport();
      },
      years() {
      var years = [];
      for(var i  = 0 ; i < 10 ; i++) {
        years.push({ text: ''+(this.maxYear - i), value: this.maxYear - i })
      }
      return years;
      },
      days() {
      var days = [];
      const lastDayOfMonth = new Date(this.currentYear, this.currentMonth, 0).getDate();
      for(var i  = 1 ; i <= lastDayOfMonth ; i++) {
        days.push({ text: ''+i, value: i})
      }
      this.currentDay = days.length < this.currentDay ? days.length : this.currentDay
      return days;
      },
      getTranslation(ar, en) {
        return localStorage.getItem('lang') == 'ar' ? ar : en;
      },
      createBarChart() {
        const names = this.data.clothes_income.map(obj => this.getTranslation(obj.ar_name, obj.en_name));
        const values = this.data.clothes_income.map(obj => obj.order_items.length == 0 ? 0 : parseInt(obj.order_items[0].total_price));
        const data = {
          labels: names,
          datasets: [{
            label: this.$t('incomeByRial'),
            data: values,
            backgroundColor: '#4caf50', // Three shades of green
            borderColor: '#fff', // Border color
            borderWidth: 1, // Border width
          }, ],
        };
        const options = {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true, // Hide legend for a bar chart
          },
          plugins: {
            datalabels: {
              color: '#fff', // Label text color
              anchor: 'end',
              align: 'end',
              font: {
                weight: 'bold', // Make the font bold
                size: 30,
              },
              formatter: (value, context) => {
                return value; // Customize the label text as needed
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 10,
              },
            },
          },
        };
        const ctx = this.$refs.barChartCanvas.getContext('2d');
        if(this.barChart != null) {
          this.barChart.destroy();
        }
        this.barChart = new Chart(ctx, {
          type: 'bar',
          data: data,
          options: options,
        });
      },
      createBranchesBarChart() {
        const names = this.data.branches_income.map(obj => this.getTranslation(obj.ar_name, obj.en_name));
        const values = this.data.branches_income.map(obj => obj.total_price);
        const data = {
          labels: names,
          datasets: [{
            label: this.$t('incomeByRial'),
            data: values,
            backgroundColor: '#4caf50', // Three shades of green
            borderColor: '#fff', // Border color
            borderWidth: 1, // Border width
          }, ],
        };
        const options = {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true, // Hide legend for a bar chart
          },
          plugins: {
            datalabels: {
              color: '#fff', // Label text color
              anchor: 'end',
              align: 'end',
              font: {
                weight: 'bold', // Make the font bold
                size: 30,
              },
              formatter: (value, context) => {
                return value; // Customize the label text as needed
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 10,
              },
            },
          },
        };
        const ctx = this.$refs.BranchesbarChartCanvas.getContext('2d');

        if(this.branchesBarChart != null) {
          this.branchesBarChart.destroy();
        }
        this.branchesBarChart = new Chart(ctx, {
          type: 'bar',
          data: data,
          options: options,
        });
      },
      createPieChart() {
        const values = this.data.wash_types_income.map(obj => obj.order_items.length == 0 ? 0 : parseInt(obj.order_items[0].total_price));
        const names = this.data.wash_types_income.map((obj, index) => this.getTranslation(obj.ar_name, obj.en_name) + ' ' + values[index] + this.$t('rial'));
        const data = {
          labels: names,
          datasets: [{
            data: values,
            backgroundColor:  chroma.scale(['red', 'green', 'blue']).colors(values.length)
          }, ],
        };
        const options = {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: 'bottom', // Set legend position
            labels: {
              fontColor: '#333', // Set label color
            },
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                const total = dataset.data.reduce((previousValue, currentValue) => previousValue + currentValue);
                const currentValue = dataset.data[tooltipItem.index];
                const percentage = Math.floor((currentValue / total) * 100 + 0.5);
                return `${data.labels[tooltipItem.index]}: ${percentage}%`;
              },
            },
          },
        };
        const ctx = this.$refs.pieChartCanvas.getContext('2d');
        if(this.pieChart != null) {
          this.pieChart.destroy();
        }
        this.pieChart = new Chart(ctx, {
          type: 'pie',
          data: data,
          options: options,
        });
      },
      createCostPieChart() {
        var tempValues =  this.data.cost_types_costs.filter(obj => obj.costs.length != 0);
        const values = tempValues.map(obj => obj.costs.length == 0 ? 0 : parseInt(obj.costs[0].total_cost));
        const names = tempValues.map((obj, index) => this.getTranslation(obj.ar_name, obj.en_name) + ' ' + values[index] + this.$t('rial') );
        const data = {
          labels: names,
          datasets: [{
            data: values,
            backgroundColor: chroma.scale(['red', 'green', 'blue']).colors(values.length)
          }, ],
        };
        const options = {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: 'bottom', // Set legend position
            labels: {
              fontColor: '#333', // Set label color
            },
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                const total = dataset.data.reduce((previousValue, currentValue) => previousValue + currentValue);
                const currentValue = dataset.data[tooltipItem.index];
                const percentage = Math.floor((currentValue / total) * 100 + 0.5);
                return `${data.labels[tooltipItem.index]}: ${percentage}%`;
              },
            },
          },
        };
        const ctx = this.$refs.costPieChartCanvas.getContext('2d');
        if(this.costPieChart != null) {
          this.costPieChart.destroy();
        }
        this.costPieChart = new Chart(ctx, {
          type: 'pie',
          data: data,
          options: options,
        });
      },
      hasProfit: function() {
        return this.data.total_income - this.data.total_cost >= 0;
      },
      getReport: function() {
        this.loading = true;
        this.$http.get("/backend/reports/getIncome", {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          params: {
            "date": this.currentYear == null ? '' : this.currentYear+'-'+this.currentMonth+'-'+this.currentDay,
            'duration': this.duration,
            'branch_id' : this.branch_id
          },
        }).then(function(data) {
            this.loading = false;
            switch (data.status) {
              case 200:
                this.data = data.body;
                this.date = this.data.date;
                this.maxYear = data.body.current_year;
                const searchDate = new Date(this.date);
                  this.currentYear = searchDate.getFullYear();
                  this.currentMonth = searchDate.getMonth() + 1;
                  this.currentDay = searchDate.getDate()+1;
                this.createPieChart();
                this.createBarChart();
                this.createCostPieChart()
                this.createBranchesBarChart()
                break;
            }
          },
          (err) => {
            this.loading = false;
            switch (err.status) {
              case 500:
                this.error(this.$t("canNotAccess"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.error(this.$t("unexpectedError"));
                break;
            }
          });
      },
      error: function(message) {
        this.$toast.error(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
    }
  }
</script>
<style lang="css" scoped>
  #mainContent {
    text-align: right;
    direction: rtl;
  }
</style>